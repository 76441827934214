<template>
	<div class="stretch-layout relative">

		<title-bar v-if="props.searchable" with-search flat v-model="props.filter.search" class="absolute left-0 w-full z-2"/>

		<data-loader :url="props.url"
					 :as-guest="!props.requiresAuth"
					 :auth="props.requiresAuth"
					 :filter="props.filter"
					 :params="params"
					 ref="loaderComponent"
					 :passive-filter-keys="props.hasViews ? ['view'] : []"
					 :persist-filter-keys="props.hasViews ? ['search', 'view'] : ['search']"
					 on-scroll @filtering="$emit('filtering')">

			<template #loading>
				<div class="flex items-center justify-center mb-16 mt-8 sticky z-1 app-header-transition" :style="{top: $device.isDesktop ? `${56 + (props.hasViews ? 72 + 42 : 0) + 16}px` : `${(indexStore.isHeaderVisible ? 56 : 0) + 42 + (props.hasViews ? 52 : 0) + 16}px`}">
					<chip-skeleton class="w-88"/>
				</div>
				<card v-for="participationLoaderItem in 5" :key="'participation-loader-' + participationLoaderItem" xs-tile sm-tile
					  class="border-t-2 border-gray-300">
					<div>
						<profile-preview-skeleton event flat class="w-full px-16 sm:px-24"/>
						<div class="border-t">
							<participation-preview-skeleton v-if="!$slots.assignment_skeletons"/>
							<slot name="assignment_skeletons"/>
						</div>
					</div>
				</card>
			</template>

			<template v-slot="{data}">
				<template v-for="(eventAssignment, index) in data" :key="`event-${eventAssignment.event.id}`">
					<div v-if="index === 0 || $date(eventAssignment.event.start_date).format('YYYY') !== $date(data[index - 1].event.start_date).format('YYYY')"
						 class="flex items-center justify-center mb-16 mt-8 sticky z-1 app-header-transition" :style="{top: $device.isDesktop ? `${56 + (props.hasViews ? 72 + 42 : 0) + 16}px` : `${(indexStore.isHeaderVisible ? 56 : 0) + 42 + (props.hasViews ? 52 : 0) + 16}px`}">
						<chip @click="scrollToPosition(0)" clickable class="w-88">
							{{ $date(eventAssignment.event.start_date).format('YYYY') }}
						</chip>
					</div>

					<card xs-tile sm-tile :style="{borderTop: `2px solid ${eventAssignment.event.color ?? '#008200'}`}">
						<profile-preview :model="eventAssignment.event" flat class="w-full px-16 sm:px-24" :to="`/events/${eventAssignment.event._key}`">
							<template #actions>
								<chip v-if="eventAssignment.event.status.value === EventStatus.Canceled" class="mr-4 md:mr-16 none sm:flex" x-small :color="eventAssignment.event.status.color">
									{{ eventAssignment.event.status.title }}
								</chip>
								<chip v-else-if="$date(eventAssignment.event.start_date).isAfter()" class="mr-4 md:mr-16 none sm:flex" x-small>
									{{ $date(eventAssignment.event.start_date).fromNow() }}
								</chip>
								<icon :icon="icons.next" color="gray-400" class="block"/>
							</template>
						</profile-preview>

						<div v-if="eventAssignment.participations?.length" class="border-t">
							<participation-preview v-for="participation in eventAssignment.participations" :key="`participation-${participation.id}`"
												   :event="eventAssignment.event" :participation="participation"
												   @show-likes="showLikes(eventAssignment.event, participation)"
												   @show-comments="showComments(eventAssignment.event, participation)"/>
						</div>

						<div v-if="eventAssignment.positions?.length" class="border-t">
							<assignment-position-list-item v-for="position in eventAssignment.positions" :key="`position-${position.id}`" :position="position"
										   :to="position.user?.id === $auth.id ? {path: `/events/${eventAssignment.event._key}/assignment`, hash: `#${position._key}`} : undefined"/>
						</div>

						<btn v-if="props.detailsPathResolver && (eventAssignment.has_more_participations || eventAssignment.has_more_positions)" text large class="rounded-t-none border-t"
							 :to="props.detailsPathResolver(eventAssignment.event)"
							 :color="eventAssignment.event.color ?? 'primary'">
							<span>{{ $t('action.show_all') }}</span>
							<icon :icon="icons.next"/>
						</btn>

					</card>
				</template>

			</template>

			<template #missingsearch>
				<missing-info v-if="!$slots.missingsearch" card xs-tile sm-tile class="flex-grow-1" :class="{'mt-64': props.searchable}" :text="$t('missing.search')"/>
				<slot name="missingsearch"/>
			</template>
			<template #missing>
				<missing-info v-if="!$slots.missing" card xs-tile sm-tile class="flex-grow-1" :class="{'mt-64': props.searchable}" :text="props.missingText ?? $t('missing.data')"/>
				<slot name="missing"/>
			</template>

		</data-loader>

		<slot/>

		<lazy-participation-interactivity-modals v-if="selectedEvent && selectedParticipation" :event="selectedEvent" :participation="selectedParticipation"
											v-model:likes="showLikesModal" v-model:comments="showCommentsModal"/>

	</div>
</template>

<script setup lang="ts">
import {EventStatus, scrollToPosition} from "@spoferan/spoferan-ts-core";
import {mdiCalendarMultiple} from "@mdi/js";
import {nextIcon} from "@spoferan/nuxt-spoferan/icons";
import {useIndexStore} from "../store";

const props = defineProps<{
	url: string,
	filter: object,
	missingText?: string,
	requiresAuth?: boolean,
	hasViews?: boolean,
	searchable?: boolean,
	detailsPathResolver?: (event: any) => string|object,
}>();

const {$date} = useNuxtApp();
const indexStore = useIndexStore();

const params = {
	limit: 10
}

const icons = {
	event: mdiCalendarMultiple,
	next: nextIcon,
};

const loaderComponent = ref(null);
function refresh() {
	loaderComponent.value.reload(undefined, false, false);
}

const showLikesModal = ref(false);
const showCommentsModal = ref(false);
const selectedEvent = ref(null);
const selectedParticipation = ref(null);

function showLikes(event, participation) {
	selectedEvent.value = event;
	selectedParticipation.value = participation;

	nextTick(() => {
		showLikesModal.value = true;
	});
}

function showComments(event, participation) {
	selectedEvent.value = event;
	selectedParticipation.value = participation;

	nextTick(() => {
		showCommentsModal.value = true;
	});
}

defineExpose({
	refresh
});
</script>